import React, { useRef, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ENV } from '../../utils/constants';
import ReactGA from "react-ga4";

import AccordionBlocks from '../../components/accordion-blocks'
import ContentTable from '../../components/content-table';

const DetailTwoColumn = ({ isLoading, hasDisclosure }) => {
	const { state } = useLocation();
	const content = useRef(null);
	const history = useHistory();
	const [cleanedSidebar, setCleanedSidebar] = useState(null);
	const [cleanedIntro, setCleanedIntro] = useState(null);
	const [productCleanedContent, setProductCleanedContent] = useState(null);
	const [cleanedContent, setCleanedContent] = useState(null);
	const data = state;

	useEffect(() => {
		const sbLinkRegEx = new RegExp(/<(a).*(href=".*?")>(.*?)<\/a>/, 'g');
		const allLinksRegEx = new RegExp(/<(a).*(href=".*?")>(.*?)<\/a>/, 'g');
		const wbLinksRegEx = new RegExp(/<a.*?href=["'].*?wesbanco\..*?["'].*?>(.*?)<\/a>/, 'g');
		const introLinksRegEx = (window.location.hostname === 'localhost' || window.location.hostname.indexOf( '.test' ) !== -1)
			? new RegExp(/<a.*?href=["'].*?wesbanco.test.*?["'].*?>(.*?)<\/a>/, 'g')
			: new RegExp(/<a.*?href=["'].*?wesbanco.com.*?["'].*?>(.*?)<\/a>/, 'g');
		const locationLinksRegEx = new RegExp(/<a.*?href=["'].*?\/locations.*?["'].*?>(?:.|\r|\n)*?<\/a>/, 'g');
		const locationLinks = locationLinksRegEx.exec(data.acf.intro_cta.url);

		const sideBarLinks = data.acf.sidebar_content.matchAll(sbLinkRegEx);
		const introLinks = data.acf.intro_content.matchAll(introLinksRegEx);
		const productContentLinks = data.acf.product_content.matchAll(wbLinksRegEx);
		const contentLinks = data.acf.content.matchAll(wbLinksRegEx);

		// G4 Tracking
		try {
			setTimeout(_ => {
				if (window.location.hostname === 'kiosk.wesbanco.com') {
					ReactGA.initialize("G-HVTY8XYXVM");
					ReactGA.send({ hitType: "pageview", page_title: `${state.title}` });
				}
			}, 2000);
		} catch (err) {
			console.log(`ERROR with G4:`, err)
		}

		isLoading(false);

		// disclosures
		if (content.current.innerHTML) {
			showDisclosure();
		}

		// accordions
		if (data.acf.accordion_blocks) {
			showAccordionBlocks();
		}

		// remove <a>,
		// replace w/ <span>
		if ( sideBarLinks ) {
			for (const link of sideBarLinks) {
				data.acf.sidebar_content =
					data.acf.sidebar_content.slice(0, data.acf.sidebar_content.indexOf(link[0]))
					+ (`<span>${link[3]}</span>`)
					+ data.acf.sidebar_content.slice((data.acf.sidebar_content.indexOf(link[0]) + link[0].length), data.acf.sidebar_content.length);
				setCleanedSidebar(data.acf.sidebar_content);
			};
		}

		if ( introLinks ) {
			for (const link of introLinks) {
				data.acf.intro_content =
					data.acf.intro_content.slice(0, data.acf.intro_content.indexOf(link[0]))
					+ (`<span>${link[1]}</span>`)
					+ data.acf.intro_content.slice((data.acf.intro_content.indexOf(link[0]) + link[0].length), data.acf.intro_content.length);
				setCleanedIntro(data.acf.intro_content);
			};
		}

		if ( productContentLinks ) {
			for (const link of productContentLinks) {
				data.acf.product_content =
					data.acf.product_content.slice(0, data.acf.product_content.indexOf(link[0]))
					+ (`${link[1]}`)
					+ data.acf.product_content.slice((data.acf.product_content.indexOf(link[0]) + link[0].length), data.acf.product_content.length);
					setProductCleanedContent(data.acf.product_content);
			};
		}

		if ( contentLinks ) {
			for (const link of contentLinks) {

				// let apple / google links pass
				if (link[0].indexOf('apple.com') > 0 || link[0].indexOf('google.com') > 0) {
					data.acf.content =
						data.acf.content.slice(0, data.acf.content.indexOf(link[0]))
						+ (`${link[0].replace('<a', '<span')}`)
						+ data.acf.content.slice((data.acf.content.indexOf(link[0]) + link[0].length), data.acf.content.length);
						setCleanedContent(data.acf.content);
				}

				if (link[0].indexOf('apple.com') < 0 && link[0].indexOf('google.com') < 0) {
					data.acf.content =
						data.acf.content.slice(0, data.acf.content.indexOf(link[0]))
						+ (`${link[1]}`)
						+ data.acf.content.slice((data.acf.content.indexOf(link[0]) + link[0].length), data.acf.content.length);
						setCleanedContent(data.acf.content);
				}
			};
		}

	}, []);

	if (!data) {
		history.push('/')
		return false;
	}

	window.scrollTo({ top: 0 });

	// show disclosures
	function showDisclosure() {
		let { disclosure_content } = data.acf;

		// send up disclosure content to parent component
		if (disclosure_content) {
			hasDisclosure(true, disclosure_content);
		}

		if (content.current.innerHTML.indexOf('<sup>') > -1) {
			const supTags = content.current.getElementsByTagName('sup');

			[...supTags].map((el) => {
				el.addEventListener('click', () => {
					window.scrollTo({
						top: document.getElementById('disclosure').getBoundingClientRect().top,
						behavior: 'smooth'
					})
				});
			})
		}
	}

	// show accordions
	function showAccordionBlocks() {
		return (
			<AccordionBlocks sections={data.acf.accordion_blocks} />
		)
	}

	// show content tables
	// function showContentTable() {
	// 	return (
	// 		<ContentTable sections={data.acf.ct_sections} />
	// 	)
	// }

	return (
		<motion.section
			className="v-detail-two-column"
			ref={content}
			animate={{ opacity: [0, 1] }}
			transition={{ duration: .2 }}
		>
			<div className="v-detail-two-column__main">
				<div className="v-detail-two-column__main-inner">
					<div className="v-detail-two-column__main-img-wrap">
						<img className="v-detail-two-column__main-img"
							src={data.img}
							alt={data.title}
							width="540"
							height="180"
							style={{'objectPosition': `${data.imgFocalX}%  ${data.imgFocalY}%`}}
						/>
					</div>
					<h3 className="v-detail-two-column__title" dangerouslySetInnerHTML={{ __html: data.title }} />
					<div className="v-detail-two-column__subtitle"><strong dangerouslySetInnerHTML={{ __html: data.acf.intro_sub_title }} /></div>
					{
						( cleanedIntro )
							? <div className="v-detail-two-column__main-content" dangerouslySetInnerHTML={{ __html: cleanedIntro }} />
							: <div className="v-detail-two-column__main-content" dangerouslySetInnerHTML={{ __html: data.acf.intro_content }} />
					}
					{
						( productCleanedContent )
							? <div className="v-detail-two-column__main-content" dangerouslySetInnerHTML={{ __html: productCleanedContent }} />
							: <div className="v-detail-two-column__main-content" dangerouslySetInnerHTML={{ __html: data.acf.product_content }} />
					}
					{
						( cleanedContent )
							? <div className="v-detail-two-column__main-content" dangerouslySetInnerHTML={{ __html: cleanedContent }} />
							: <div className="v-detail-two-column__main-content" dangerouslySetInnerHTML={{ __html: data.acf.content.replace('<a', '<span') }} />
					}
					{
						( data.acf.accordion_blocks ) &&
							<AccordionBlocks sections={data.acf.accordion_blocks} />
					}
					{
						( data.acf.ct_sections ) &&
						<div className="v-detail-two-column__card-content">
							<ContentTable sections={data.acf.ct_sections} />
						</div>
					}
				</div>
			</div>

			<div className="v-detail-two-column__sidebar">
				<div className="v-detail-two-column__sidebar-inner">
					{
						( data.acf.intro_cta && (data.acf.intro_cta.url.indexOf(`wesbanco.${ENV}/locations`) < 0 )
							&& data.acf.intro_cta && (data.acf.intro_cta.url.indexOf(`wesbanco.${ENV}/investment`) < 0 )
							&& data.acf.intro_cta && (data.acf.intro_cta.url.indexOf(`.pdf`) < 0 )
						) &&
						<div className="v-detail-two-column__sidebar-cta">
							{
								( data.acf.intro_cta )
								? <a href={data.acf.intro_cta.url} className="v-button" dangerouslySetInnerHTML={{ __html: data.acf.intro_cta.title }}/>
								: <a href={data.acf.intro_cta.url} className="v-button" dangerouslySetInnerHTML={{ __html: data.acf.intro_cta_text }}/>
							}
						</div>
					}
					<div className="v-detail-two-column__sidebar-content">
						<h3 className="zeta" dangerouslySetInnerHTML={{ __html: data.acf.sidebar_title }} />
						{ ( cleanedSidebar )
							? <span dangerouslySetInnerHTML={{ __html: cleanedSidebar }}></span>
							: <span dangerouslySetInnerHTML={{ __html: data.acf.sidebar_content }}></span>
						}
					</div>
				</div>
			</div>
		</motion.section>
	)
}

export default DetailTwoColumn
