import React from 'react'
import { useHistory, useLocation } from "react-router-dom";

export const Button = ({ title, type }) => {
	const location = useLocation();
	const history = useHistory();

	function buttonType(type) {
		if (type === 'back') {
			history.goBack();
		}
	}

	return (
		<>
			{
				location.pathname !== '/' &&
				<button onClick={() => buttonType(type) } className="v-header__back-btn v-button v-button--sm">{title}</button>
			}
		</>
	)
}

export default Button;
