import React, { useRef } from 'react'

const AccordionBlocks = (sections) => {
	const aAnswer = useRef(null);

	const toggleAccordion = (e) => {
		if (e.currentTarget.nextElementSibling.classList.contains('open')) {
			e.currentTarget.nextElementSibling.style.display = 'none';
			e.currentTarget.nextElementSibling.style.height = '0';
			e.currentTarget.nextElementSibling.classList.remove('open');
		} else {
			e.currentTarget.nextElementSibling.style.display = 'block';
			e.currentTarget.nextElementSibling.style.height = '100%';
			e.currentTarget.nextElementSibling.classList.add('open');
		}
	}
	return (
		<div className="v-detail-two-column__main-content">
			<div className="v-accordions__inner">
				{
					(sections.sections[0].title) &&
					<h2 className="v-accordions__title">{sections.sections[0].title}</h2>
				}
				<ul className="v-accordions__list">
					{
						sections.sections[0].rows.map(el => {
							return (
								<li key={el.title} className="v-accordions__item">
									<button onClick={toggleAccordion} className="v-accordions__trigger" type="button" aria-expanded="false"><h3 className="v-accordions__question">{el.title}</h3></button>
									<div ref={aAnswer} className="v-accordions__answer">
										<div className="v-accordions__answer-inner" dangerouslySetInnerHTML={{ __html: el.content }}/>
									</div>
								</li>
							)
						})
					}
				</ul>
			</div>
		</div>
	)
}

export default AccordionBlocks;
