import React from 'react'

import icon from '../../img/fdic-house.svg'

export const Footer = () => {
	const date = new Date();
	const year = date.getFullYear();

	return (
		<footer className="v-footer">
			<p><small>Copyright © {year} · WesBanco, Inc. All Rights Reserved.</small></p>
			<p className="v-footer__text">
				<small>WesBanco Bank, Inc. is a Member FDIC. Equal Housing Lender</small>
				<img
					src={icon}
					alt="FDIC"
					className="v-footer__icon"
				/>
			</p>
		</footer>
	)
}

export default Footer
