import React, { useState } from 'react'

const ContentTable = (sections) => {

	// remove anchor tags
	if (sections.sections[0]) {
		[...sections.sections[0].rows].forEach(row => {
			if (row.content.indexOf('<a') > 0 ) {
				row.content = row.content.replaceAll('<a', '<span');
				}
		});
	}

	return (
		<div>
			<ul className="v-content-table__list">
				{
					(sections.sections[0].title) &&
					<h4>{sections.sections[0].title}</h4>
				}
				{
					(sections.sections[0].rows) &&
						sections.sections[0].rows.map((el, index) => {
							return (
								<li className="v-content-table__item" key={index}>
									<div className="v-content-table__header">
										{
											( el.title ) &&
											<h3 className="v-content-table__header-title" dangerouslySetInnerHTML={{ __html: el.title}} />
										}
									</div>
									{
										<div className="v-content-table__content" dangerouslySetInnerHTML={{ __html: el.content }} />
									}
								</li>
							)
						})
				}
				</ul>
		</div>
	)
}

export default ContentTable
