const route = (item, parent, pageType, oneOffImages = null) => {
	let path = null;
	let title = null;

	// SLIDER ROUTES
	if (parent === 'slider') {
		if (item.template === 'template-credit-card.php') {
			title = item.title.toLowerCase().split(" ").join("-");
			path = `/detail-comparison/${title}/${item.id}`;
		}
		else if (item.template === 'template-default-page.php') {
			title = item.title.toLowerCase().split(" ").join("-");
			path = `/detail-no-hero/${title}/${item.id}`;
		}
		else if (item.title === 'Careers') {
			title = item.title.toLowerCase().split(" ").join("-");
			path = `/detail-careers/${title}/${item.id}`;
		}
		else if (item.type === 'insight') {
			title = item.title.toLowerCase().split(" ").join("-");
			path = `/detail-insight/${item.id}`;
		}
		else if (item.type === 'mortgage') {
			title = item.title.toLowerCase().split(" ").join("-");
			path = `/detail-two-column/${title}/${item.id}`;
		}
		else if (item.title.indexOf("Wellness") >= 0) {
			title = item.title.toLowerCase().split(" ").join("-");
			path = `/list-insight/${title}/${item.id}`;
		}
		else if (item.title.indexOf("Calculators") >= 0) {
			title = item.title.toLowerCase().split(" ").join("-");
			path = `/list-insight/${title}/${item.id}`;
		}
		else if (item.title.indexOf("Grow") >= 0) {
			title = item.title.toLowerCase().split(" ").join("-");
			path = `/list-insight/${title}/${item.id}`;
		}
		else {
			if (item.object_id) {
				path = `/list-intro/${item.object}/${item.object_id}`;
			} else {
				path = `/list-intro/${item.type}/${item.id}`;
			}
		}

		return {
			item,
			path,
			title
		}
	}

	// GENERAL ROUTES
	// routes typically depend on
	// template / pages from CMS
	if (pageType === 'secondary') {
		title = item.title.toLowerCase().split(" ").join("-");
		path = `/secondary/${title}/${item.id}`;
	}
	else if (item.template === 'template-credit-card.php') {
		title = item.title.toLowerCase().split(" ").join("-");
		path = `/detail-comparison/${title}/${item.id}`;
	}
	else if (item.template === 'template-default-page.php' || item.title === 'Trusts') {
		title = item.title.toLowerCase().split(" ").join("-");
		path = `/detail-no-hero/${title}/${item.id}`;
	}
	else if (item.title === 'Careers') {
		title = item.title.toLowerCase().split(" ").join("-");
		path = `/detail-careers/${title}/${item.id}`;
	}
	else if ( (item.parent === parent && parent !== undefined) || item.title === 'Investment Advice') {
		title = item.title.toLowerCase().split(" ").join("-");
		path = `/detail-two-column/${title}/${item.id}`;
	}
	else if (item.title.indexOf("Wellness") >= 0) {
		if (item.image) {
			item.img = item.image
		}

		title = item.title.toLowerCase().split(" ").join("-");
		path = `/list-insight/${title}/${item.id}`;
	}
	else if (item.title.indexOf("Calculators") >= 0) {
		if (item.image) {
			item.img = item.image
		}

		title = item.title.toLowerCase().split(" ").join("-");
		path = `/list-insight/${title}/${item.id}`;
	}
	else if (item.title.indexOf("Grow") >= 0) {
		if (item.image) {
			item.img = item.image
		}

		title = item.title.toLowerCase().split(" ").join("-");
		path = `/list-insight/${title}/${item.id}`;
	}
	else {
		if (item.object_id) {
			path = `/list-intro/${item.object}/${item.object_id}`;
		} else {
			path = `/list-intro/${item.type ? item.type : item.object}/${item.id}`;
		}
	}

	return {
		item,
		path,
		title
	}
}

export default route;