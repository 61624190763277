import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom';
import { useKeenSlider } from "keen-slider/react"
import { motion } from 'framer-motion';

// utils
import { WP_API } from '../../utils/constants'
import route from '../../utils/route'

// styles
import "keen-slider/keen-slider.min.css"

const Slider = ({ slides }) => {
	const [pause, setPause] = useState(false);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [items, setItems] = useState(null);
	const timer = useRef();
	let parent = null;
	let pageType = null;
	const sliderOptions = {
		initial: 0,
		loop: true,
		duration: 1000,
		dragStart: () => {
				setPause(true)
		},
		dragEnd: () => {
				setPause(false)
		},
		slideChanged(s) {
			setCurrentSlide(s.details().relativeSlide)
		},
	}

	const [sliderRef, slider] = useKeenSlider(sliderOptions);

	useEffect(() => {

		if (items) {
			sliderRef.current.addEventListener('mouseover', () => {
				setPause(true);
			});

			sliderRef.current.addEventListener('mouseout', () => {
				setPause(false);
			});
		}
	}, []);

	useEffect(() => {

			timer.current = setInterval(() => {
				if (!pause && slider) {
					slider.next();
				}
			}, 7000)

			return () => {
				clearInterval(timer.current)
			}

	}, [pause, slider]);

	useEffect(() => {
		async function awaitSlides() {
			const slidesArr = [];

			await Promise.all([...slides.acf.kiosk_slides.kiosk_slide].map(async slide => {
				let type = null;
				let imgId = null;
				const id = slide.kiosk_slide_object.ID;

				// ! needs to be one of these cases
				// ! otherwise will not be added
				switch (slide.kiosk_slide_object.post_type) {
					case 'insight':
						type = 'insight';
						break;

					case 'mortgage':
						type = 'mortgage';
						break;

					case 'personal':
						type = 'personal';
						break;

					case 'business':
						type = 'business';
						break;

					default:
						break;
				}

				if (type) {
					await fetch(`${WP_API}/${type}/${id}`)
						.then(response => response.json())
						.then( async page => {

							// WB wants to change a slide title, but not the WP page title
							// if (page.title.rendered === 'Mortgage Calculators') {
							// 	page.title.rendered = 'Financial Calculators'
							// }

							const customPage = {
								id: page.id,
								type: page.type,
								parent: page.parent,
								title: slide.kiosk_title ? slide.kiosk_title : page.title.rendered,
								slug: page.slug,
								template: page.template,
								acf: page.acf,
								img: null,
								img_details: null,
								imgFocalX: null,
								imgFocalY: null
							};

							if (slide.kiosk_slide_image) {
								imgId = slide.kiosk_slide_image.ID;
							} else if (page.acf.featured_image) {
								imgId = page.acf.featured_image;
							} else if (page.acf.page_thumbnail) {
								imgId = page.acf.page_thumbnail;
							} else if (page.acf.background_image) {
								imgId = page.acf.background_image;
							}

							if ( ! imgId ) {
								return customPage;
							}

							await fetch(`${WP_API}/media/${imgId}`)
								.then( res => res.json() )
								.then( image => {
									if (image.guid) {
										customPage.img = image.guid.rendered;
										customPage.img_details = image.media_details;
									};

									if (image.meta?._focal_point_kiosk_x) {
										customPage.imgFocalX = image.meta._focal_point_kiosk_x;
										customPage.imgFocalY = image.meta._focal_point_kiosk_y;
									}

								} );
							slidesArr.push(customPage);

							return false;

					});
				}

				}));

				//! todo: set slides at opacity: 0; until slider is refreshed.
				if (slider) {
					slider.refresh(sliderOptions);
				}

				setItems(slidesArr);
			}
			awaitSlides();
	}, [slider]);

	return (
		<motion.section
			animate={{ opacity: [0, 1] }}
			transition={{ duration: .25, ease: "easeOut" }}
			className="v-slider"
		>
			<div className="v-slider__wrap">
				<div className="v-slider__images keen-slider" ref={sliderRef}>
					{
						(items) ?
						items.map(item => {


							if (item.title === 'Personal' || item.title === 'Business'  || item.title === 'Investments') {
								pageType = 'secondary';
							}

							let page = route(item, parent='slider', pageType);
							pageType = null;

							return (
								<div key={item.id} className="v-slider__slide keen-slider__slide number-slide1">
									<div className="v-slider__slide-content">
										<div className="v-slider__slide-title"><h1>{item.title}</h1></div>
										<Link to={{ pathname: page.path, state: page.item }} className="v-button v-slider__slide-button">Learn More</Link>
									</div>
									<img className="v-slider__img" style={{'objectPosition': `${item.imgFocalX}%  ${item.imgFocalY}%`}} src={item.img} alt={item.title} width="1000" height="300" />
								</div>
							)
						}) : <div className="v-slider__slide keen-slider__slide"></div>
					}
				</div>
				<div className="v-slider__dots">
					{items ? (
						items.map((item, idx) => {
							return (
								<button
									key={idx}
									onClick={() => { slider.moveToSlideRelative(idx) }}
									className={"v-slider__dot" + (currentSlide === idx ? " v-slider__dot--active" : "")}
								/>
							)
						})
					) : <button className="v-slider__dot"></button>}
				</div>
			</div>
		</motion.section>
	)
}

export default Slider
