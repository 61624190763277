import React, { useRef, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReactGA from "react-ga4";

const DetailInsight = ({ isLoading, hasDisclosure }) => {
	const { state } = useLocation();
	const data = state;
	const content = useRef(null);
	const history = useHistory();

	useEffect(() => {
		isLoading(false);
		hasDisclosure(false);

		// G4 Tracking
		try {
			setTimeout(_ => {
				if (window.location.hostname === 'kiosk.wesbanco.com') {
					ReactGA.initialize("G-HVTY8XYXVM");
					ReactGA.send({ hitType: "pageview", page_title: `${state.title}` });
				}
			}, 2000);
		} catch (err) {
			console.log(`ERROR with G4:`, err)
		}

		// banzai calculator script
		const banzai = document.createElement( 'script' );
		banzai.src  = 'https://teachbanzai.com/coach/styles.js?subdomain=wesbanco';
		banzai.id    = 'something-unique';
		const last   = document.querySelector( 'body > :last-child' );
		last.parentNode.insertBefore( banzai, last.nextSibling );
		// end banzai calculator script

		if (content.current.innerHTML) {
			showDisclosure();
		}
	}, []);

	if (!data) {
		history.push('/')
		return false;
	}

	// console.log(`data`, data.acf)

	window.scrollTo({ top: 0 });

	function showDisclosure() {
		let { disclosure_content } = data.acf;

		// send up disclosure content
		// to parent component
		if (disclosure_content) {
			hasDisclosure(true, disclosure_content);
		}

		if (content.current.innerHTML.indexOf('<sup>') > -1) {
			const supTags = content.current.getElementsByTagName('sup');

			[...supTags].map((el) => {
				el.addEventListener('click', () => {
					window.scrollTo({
						top: document.getElementById('disclosure').getBoundingClientRect().top,
						behavior: 'smooth'
					})
				})
			})
		}
	}

	return (
		<motion.section
			className="v-detail-insight"
			ref={content}
			animate={{ opacity: [0, 1] }}
			transition={{ duration: .2 }}
		>
			<div className="container">
				<h1 className="v-detail-insight__header" dangerouslySetInnerHTML={{ __html: data.title }} />
				<div className="v-detail-insight__content">
					<div className="v-detail-insight__shadow"></div>
					<div className="v-detail-insight__card-container">
						<div className="v-detail-insight__card">
							<div className="v-detail-insight__card-img-wrap">
								<img className="v-detail-insight__card-img" style={{'objectPosition': `${data.imgFocalX}%  ${data.imgFocalY}%`}}  src={data.img} alt={data.title} />
							</div>
							<div className="v-detail-insight__card-content" dangerouslySetInnerHTML={{ __html: data.acf.content }} />
						</div>
					</div>
				</div>
			</div>
		</motion.section>
	)
}

export default DetailInsight;