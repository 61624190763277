import React from 'react'


export const Main = ({ children }) => {
	return (
		<main className="v-main">
				{children}
		</main>
	)
}

export default Main;
