import React, { useRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReactGA from "react-ga4";

// utils
import { WP_API } from '../../utils/constants'

const DetailCareers = ({ isLoading, hasDisclosure }) => {
	const html = useRef(null);
	const { state } = useLocation();
	const [content, setContent] = useState(null);

	window.scrollTo({ top: 0 });

	useEffect(() => {

		// G4 Tracking
		try {
			setTimeout(_ => {
				if (window.location.hostname === 'kiosk.wesbanco.com') {
					console.log('stet', state.title);
					ReactGA.initialize("G-HVTY8XYXVM");
					ReactGA.send({ hitType: "pageview", page_title: `${state.title}` });
				}

			}, 2000);
		} catch (err) {
			console.log(`ERROR with G4:`, err)
		}

		if (state.title === 'Careers') {

			isLoading(true);
			hasDisclosure(false);

			fetch(`${WP_API}/pages/${state.id}`)
				.then(response => response.json())
				.then( async page => {
					let imgId;

					const customPage = {
						id: page.id,
						parent: page.parent,
						title: page.title.rendered,
						slug: page.slug,
						template: page.template,
						acf: page.acf,
						img: null,
						img_details: null,
						imgFocalX: null,
						imgFocalY: null
					};

					if (page.acf.featured_image) {
						imgId = page.acf.featured_image;
					} else if (page.acf.page_thumbnail) {
						imgId = page.acf.page_thumbnail;
					} else if (page.acf.background_image) {
						imgId = page.acf.background_image;
					}

					if ( ! imgId ) {
						return customPage;
					}

					await fetch(`${WP_API}/media/${imgId}`)
						.then( res => res.json() )
						.then( image => {
							if (image.guid) {
								customPage.img = image.guid.rendered;
								customPage.img_details = image.media_details;
								isLoading(false);
							}

							if (image.meta?._focal_point_kiosk_x) {
								customPage.imgFocalX = image.meta._focal_point_kiosk_x;
								customPage.imgFocalY = image.meta._focal_point_kiosk_y;
							}
						});

					setContent(customPage);
					return customPage;
				});
		}

	}, []);

	return (
		<motion.section
			className="v-detail-two-column"
			ref={html}
			animate={{ opacity: [0, 1] }}
		>
			{
				(content) &&
				<>
					<div className="v-detail-two-column__main">
						<div className="v-detail-two-column__main-inner">
							<div className="v-detail-two-column__main-img-wrap">
								<img className="v-detail-two-column__main-img"
									src={content.img}
									alt={content.title}
									width="540"
									height="180"
									style={{'objectPosition': `${content.imgFocalX}%  ${content.imgFocalY}%`}}
								/>
							</div>
							<h3 className="v-detail-two-column__title" dangerouslySetInnerHTML={{ __html: content.title }} />
							<div className="v-detail-two-column__main-content" dangerouslySetInnerHTML={{ __html: content.acf.intro_content }} />
							<div className="v-detail-two-column__main-content" dangerouslySetInnerHTML={{ __html: content.acf.text }} />
						</div>
					</div>
					<div className="v-detail-two-column__sidebar">
						<div className="v-detail-two-column__sidebar-inner">
							{
								( content.acf.intro_cta.length !== 0 ) &&
								<div className="v-detail-two-column__sidebar-cta">
									{
										( content.acf.intro_cta)
										? <a href={content.acf.intro_cta.url} className="v-button" dangerouslySetInnerHTML={{ __html: content.acf.intro_cta.title }}/>
										: <a href={content.acf.intro_cta.url} className="v-button" dangerouslySetInnerHTML={{ __html: content.acf.intro_cta_text }}/>
									}
								</div>
							}
							<div className="v-detail-two-column__sidebar-content">
								<h3 className="zeta" dangerouslySetInnerHTML={{ __html: content.acf.sidebar_title }} />
								<span dangerouslySetInnerHTML={{ __html: content.acf.sidebar_content }}></span>
							</div>
						</div>
					</div>
				</>
			}
		</motion.section>
	)
}

export default DetailCareers
