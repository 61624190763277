import React from 'react'
import { useHistory, Link } from "react-router-dom";

// styles
import logo from '../../img/logo-black.svg';


export const LogoHeader = () => {
	const history = useHistory();

	return (
		<>
			<div className="v-header__logo-wrap">
				<Link to={'/'}>
					<img
						src={logo}
						alt="logo"
						className="v-header__logo"
					/>
				</Link>
			</div>
		</>
	)
}
