import React from 'react'
import { Link } from "react-router-dom";
import { motion } from 'framer-motion';

// components
import Card from '../card'

// utils
import route from '../../utils/route';

const CardList = ({ pages, parent, oneOffImages = null }) => {
	let pageType = null;

	// no idea why, but parent id '424' does
	// not need to be in reverse order.
	if (parent && parent !== 424) {
		pages = pages.slice().reverse();
	}

	// animations
	const list = {
		visible: {
			opacity: 1,
			y: [10, 0],
			transition: {
				staggerChildren: .1,
			},
		},
		hidden: {
			opacity: 0,
			transition: {
				when: "afterChildren",
			},
		},
	}

	const item = {
		visible: { opacity: 1, y: 0, transition: { ease: 'easeOut' } },
		hidden: { opacity: 0, y: 10 },
	}

	return (
		<motion.section
			className={parent === undefined ? 'v-morph-product-grid v-morph-product-grid--tall': 'v-morph-product-grid'}
			animate={{ opacity: [0, 1] }}
			transition={{ duration: .2 }}
		>
			<div className="container">
				<div className="v-morph-product-grid__inner">
					<div className="v-morph-product-grid__content">
						<motion.ul
							initial="hidden"
							animate="visible"
							variants={list}
							className="v-morph-product-grid__list"
						>
							{
								(pages) &&
									pages.map(el => {
										if (el.title === 'Personal' || el.title === 'Business' || ( el.title === 'Investments' && !el.onSecondary )) {
											pageType = 'secondary';
										}

										// DONT SHOW FOLLOWING PAGES:
										if (el.title.indexOf( 'Our Administrators' ) > -1) return false;
										if (el.title.indexOf( 'Zelle' ) > -1) return false;

										let page = route(el, parent, pageType);
										// reset
										pageType = null;

										return (
											<motion.li
												variants={item}
												key={page.item.id}
												className="v-morph-product-grid__item"
											>
												<Link className="v-morph-product-grid__link" to={{pathname: page.path, state: page.item}} >
													<Card
														key={page.item.id}
														data={page.item}
														path={page.path} />
												</Link>
											</motion.li>
										)
									})
							}
						</motion.ul>
					</div>
				</div>
			</div>
		</motion.section>
	)
}

export default CardList
