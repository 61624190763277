import React, { useRef, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import ContentTable from '../../components/content-table';
import ReactGA from "react-ga4";

const DetailNoHero = ({ isLoading, hasDisclosure }) => {
	const { state } = useLocation();
	const data = state;
	const content = useRef(null);
	const history = useHistory();
	const [cleanedContent, setCleanedContent] = useState(null);
	const [productCleanedContent, setProductCleanedContent] = useState(null);

	useEffect(() => {
		const linksRegEx = new RegExp(/<a.*?href=["'].*?\/locations.*?["'].*?>(?:.|\r|\n)*?<\/a>/, 'g');
		const wbLinksRegEx = new RegExp(/<a.*?href=["'].*?wesbanco\..*?["'].*?>(.*?)<\/a>/, 'g');

		const links = linksRegEx.exec(data.acf.content);
		const productContentLinks = data.acf.product_content.matchAll(wbLinksRegEx);
		const contentLinks = data.acf.content.matchAll(wbLinksRegEx);

		// G4 Tracking
		try {
			setTimeout(_ => {
				if (window.location.hostname === 'kiosk.wesbanco.com') {
					ReactGA.initialize("G-HVTY8XYXVM");
					ReactGA.send({ hitType: "pageview", page_title: `${state.title}` });
				}
			}, 2000);
		} catch (err) {
			console.log(`ERROR with G4:`, err)
		}

		isLoading(false);

		if (content.current.innerHTML) {
			showDisclosure();
		}

		if (data.acf.ct_sections) {
			showContentTable();
		}

		// remove links to "/locations" page
		if ( links ) {
			[...links].forEach(link => {
				if (link.indexOf('/locations') > 0) {
					data.acf.content = data.acf.content.slice(0, data.acf.content.indexOf(link)) + ("") + data.acf.content.slice((data.acf.content.indexOf(link) + link.length), data.acf.content.length);
					setCleanedContent(data.acf.content);
					return data.acf.content;
				}
			});
		}

		if ( productContentLinks ) {
			for (const link of productContentLinks) {
				data.acf.product_content =
					data.acf.product_content.slice(0, data.acf.product_content.indexOf(link[0]))
					+ (`${link[1]}`)
					+ data.acf.product_content.slice((data.acf.product_content.indexOf(link[0]) + link[0].length), data.acf.product_content.length);
					setProductCleanedContent(data.acf.product_content);
			};
		}

		if ( contentLinks ) {
			for (const link of contentLinks) {

				// let apple / google links pass
				if (link[0].indexOf('apple.com') > 0 || link[0].indexOf('google.com') > 0) {
					data.acf.content =
						data.acf.content.slice(0, data.acf.content.indexOf(link[0]))
						+ (`${link[0].replace('<a', '<span')}`)
						+ data.acf.content.slice((data.acf.content.indexOf(link[0]) + link[0].length), data.acf.content.length);
						setCleanedContent(data.acf.content);
				}

				if (link[0].indexOf('apple.com') < 0 && link[0].indexOf('google.com') < 0) {
					data.acf.content =
						data.acf.content.slice(0, data.acf.content.indexOf(link[0]))
						+ (`${link[1]}`)
						+ data.acf.content.slice((data.acf.content.indexOf(link[0]) + link[0].length), data.acf.content.length);
						setCleanedContent(data.acf.content);
				}
			};
		}

	}, []);

	if (!data) {
		history.push('/')
		return false;
	}

	window.scrollTo({ top: 0 });

	function showDisclosure() {
		let { disclosure_content } = data.acf;

		// send up disclosure content
		// to parent component
		if (disclosure_content) {
			hasDisclosure(true, disclosure_content);
		}

		if (content.current.innerHTML.indexOf('<sup>') > -1 && data.slug !== 'compare-checking-accounts') {
			const supTags = content.current.getElementsByTagName('sup');

			[...supTags].map((el) => {
				el.addEventListener('click', () => {
					window.scrollTo({
						top: document.getElementById('disclosure').getBoundingClientRect().top,
						behavior: 'smooth'
					})
				})
			})
		}
	}

	function showContentTable() {
		return (
			<ContentTable sections={data.acf.ct_sections} />
		)
	}

	return (
		<motion.section
			className="v-detail-no-hero"
			ref={content}
			animate={{ opacity: [0, 1] }}
			transition={{ duration: .2 }}
		>
			<div className="container">
				<h1 className="v-detail-no-hero__header" dangerouslySetInnerHTML={{ __html: data.title }} />
				<div className="v-detail-no-hero__content">
					<div className="v-detail-insight__shadow"></div>
					<div className="v-detail-no-hero__card-container">

						<div className="v-detail-insight__card">
							{/* remove hero */}
							{/* <div className="v-detail-no-hero__card-img-wrap">
								<img className="v-detail-no-hero__card-img"
									src={data.img}
									alt={data.title}
									width="540"
									height="180"
								/>
							</div> */}
							<div className="v-detail-insight__card-outer">
								{ ( data.acf.content !== '' ) ?
									(cleanedContent)
										? <div className="v-detail-no-hero__card-content" dangerouslySetInnerHTML={{ __html: cleanedContent }} />
										: <div className="v-detail-no-hero__card-content" dangerouslySetInnerHTML={{ __html: data.acf.content }} />
									:
									<div className="v-detail-no-hero__card-content">
										{showContentTable(data)}
									</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		</motion.section>
	)
}

export default DetailNoHero