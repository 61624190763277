import React, { useEffect, useState } from 'react'
import { useLocation, Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import ReactGA from "react-ga4";

// utils
import { WP_API } from '../../utils/constants'
import { truncate } from '../../utils/helpers'

const ListInsight = ({ isLoading, hasDisclosure }) => {
	const [cards, setCards] = useState(null);
	const [loaded, setLoaded] = useState(false);
	const location = useLocation(null);
	const locationURL = location.pathname.split('/');
	const title = locationURL[2].split("-").map(word => `${word[0].toUpperCase()}${word.substr(1)}`).join(" ");
	let route = null;

	// * Get title by URL. Check URL title
	// * then direct to specific endpoint
	// 141 = Wellness Planning filtered articles
	// 159 = Calculators & Tools filtered articles
	// 157 = Grow Your Business filtered articles
	if (title === 'Calculators & Tools' || title === 'Mortgage Calculators') {
		route =	`${WP_API}/insight?insight-category-revamp=159&per_page=20`;
	}

	if (title === 'Financial Wellness') {
		route =	`${WP_API}/insight?financial-wellness-life-planning=141&per_page=20`;
	}

	if (title === 'Growing Your Business') {
		route =	`${WP_API}/insight?insight-category-revamp=157&per_page=20`;
	}

	// let route = (locationURL[3] === '141')
	// 	? `${WP_API}/insight?financial-wellness-life-planning=141&per_page=20`
	// 	: `${WP_API}/insight?insight-category-revamp=159&per_page=20`;

	window.scrollTo({ top: 0 });

	useEffect(() => {
		isLoading(true);
		hasDisclosure(false);

		// G4 Tracking
		try {
			setTimeout(_ => {
				if (window.location.hostname === 'kiosk.wesbanco.com') {
					ReactGA.initialize("G-HVTY8XYXVM");
					ReactGA.send({ hitType: "pageview", page_title: `${title}` });
				}
			}, 2000);
		} catch (err) {
			console.log(`ERROR with G4:`, err)
		}

		fetch(`${route}`)
			.then(response => response.json())
			.then( async data => {
				let imgId;

				const cleanedData = await Promise.all(data.map( async (page) => {
					const customPage = {
						id: page.id,
						parent: page.parent,
						title: page.title.rendered,
						slug: page.slug,
						template: page.template,
						acf: page.acf,
						img: null,
						img_details: null
					};

					if (page.acf.featured_image) {
						imgId = page.acf.featured_image;
					} else if (page.acf.page_thumbnail) {
						imgId = page.acf.page_thumbnail;
					} else if (page.acf.background_image) {
						imgId = page.acf.background_image;
					}

					if ( ! imgId ) {
						return customPage;
					}

					await fetch(`${WP_API}/media/${imgId}`)
						.then( res => res.json() )
						.then( image => {
							if (image.guid) {
								customPage.img = image.guid.rendered;
								customPage.img_details = image.media_details;
							}

							if (image.meta?._focal_point_kiosk_x) {
								customPage.imgFocalX = image.meta._focal_point_kiosk_x;
								customPage.imgFocalY = image.meta._focal_point_kiosk_y;
							}

							setLoaded(true);
						} );

						return customPage;
					})
				);

				isLoading(false);
				setCards(cleanedData);
			});

	}, []);

	// animations
	const list = {
		visible: {
			opacity: 1,
			transition: {
				when: "beforeChildren",
				staggerChildren: .1,
			},
		},
		hidden: {
			opacity: 0
		},
	}

	const item = {
		visible: { opacity: 1, y: 0, transition: { ease: "easeOut" } },
		hidden: { opacity: 0, y: 20 },
	}

	return (
		<>
			{ loaded &&
				<motion.section
					className="v-list-insight"
					animate={{ y: [10, 0] }}
					transition={{duration: .5}}
				>
					<div className="container">
						<h1 className="v-list-insight__header">{title}</h1>
						<motion.div
							animate={{ y: [10, 0], opacity: [0, 1] }}
							transition={{duration: 1, delay: .5}}
							className="v-list-insight__content">
							<motion.div
								className="v-list-insight__shadow"
								animate={{ opacity: [0, 1] }}
							></motion.div>
								{
									(cards) &&
									<motion.ul
										initial="hidden"
										animate="visible"
										variants={list}
										className="v-list-insight__card-container"
									>
										{
											cards.map((card) => {
												return (
													<motion.li
														variants={item}
														key={card.id}
														className="v-list-insight__card"
													>
														<Link to={{ pathname: `/detail-insight/${card.id}`, state: card }} className="v-list-insight__card-link">
															<div className="v-list-insight__card-img-wrap">
																<img className="v-list-insight__card-img" style={{'objectPosition': `${card.imgFocalX}%  ${card.imgFocalY}%`}}  src={`${card.img}`} alt={card.title} />
															</div>
															<div className="v-list-insight__card-content">
																<h3 className="v-list-insight__card-title" dangerouslySetInnerHTML={{ __html: card.title }} />
																<div className="v-list-insight__card-text">{truncate(card.acf.excerpt_content, 30)}</div>
															</div>
														</Link>
													</motion.li>
												)
											})
										}
									</motion.ul>
								}
						</motion.div>
					</div>
				</motion.section>
			}
		</>
	)

}

export default ListInsight
