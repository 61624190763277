const Header = ({ children }) => {
	return (
		<header className="v-header">
			<div className="container">
				{children}
			</div>
		</header>
	)
}

export default Header;
