import React from 'react'

// utils
import { WP_IMG_PATH } from '../../utils/constants'

const Card = ({ data, path }) => {
	let img;

	if (data.title === 'Personal' || data.title === 'Business') {
		img = data.image;
	} else {
		img = (data.image)
		? `${WP_IMG_PATH}/${data.image.file}`
		: data.img;
	}

	return (
		<>
			<div className="v-morph-product-grid__image">
				<img
					src={img} alt={''}
					className="v-morph-product-grid__image-img"
					style={{'objectPosition': `${data.imgFocalX}%  ${data.imgFocalY}%`}}
				/>
			</div>
			{
				// strip <sup>*</sup> if present
				( data.title.indexOf('<sup>') > 0 )
				?
				<h3
					className="v-morph-product-grid__product-title epsilon"
					dangerouslySetInnerHTML={{ __html: data.title.substring(0, data.title.indexOf('<sup>')) }}
				/>
				:
				<h3
					className="v-morph-product-grid__product-title epsilon"
					dangerouslySetInnerHTML={{ __html: data.title }}
				/>
			}
		</>

	)
}

export default Card;