import React from 'react'
import { motion } from 'framer-motion';

const Intro = ({ img, title, intro, imgData }) => {

	return (
		<motion.section
			className="v-intro"
			animate={{ opacity: [0, 1] }}
			transition={{ duration: .2 }}
		>
			<div className="container">
				<div className="v-intro__img-wrap">
						<img className="v-intro__img" style={{'objectPosition': `${imgData.imgFocalX}%  ${imgData.imgFocalY}%`}} src={img} alt={title} />
				</div>
				<div className="v-intro__content">
					{
						(title.indexOf('<sup>') > -1)
						? <h1 className="v-intro__title" dangerouslySetInnerHTML={{ __html: title.substring(0, title.indexOf('<sup>'))}} />
						: <h1 className="v-intro__title" dangerouslySetInnerHTML={{ __html: title }} />
					}
					<span className="v-intro__content-text" dangerouslySetInnerHTML={{ __html: intro.replace('<a', '<span') }} />
				</div>
			</div>
		</motion.section>
	)
}

export default Intro;