import React, { useRef, useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ENV } from '../../utils/constants';
import ReactGA from "react-ga4";

// utils
import { WP_API } from '../../utils/constants'

const DetailComparison = ({ isLoading, hasDisclosure }) => {
	const { state } = useLocation();
	const [sections, setSections] = useState(null);
	const content = useRef(null);
	const history = useHistory();
	const data = state;

	useEffect( () => {
		const rows = [];

		// G4 Tracking
		try {
			setTimeout(_ => {
				if (window.location.hostname === 'kiosk.wesbanco.com') {
					ReactGA.initialize("G-HVTY8XYXVM");
					ReactGA.send({ hitType: "pageview", page_title: `${state.title}` });
				}

			}, 2000);
		} catch (err) {
			console.log(`ERROR with G4:`, err)
		}

		hasDisclosure(false);

		if (content) {
			showDisclosure();
		}

		async function awaitImages() {
			await Promise.all([...data.acf.ict_sections].map(async page => {
				const section = {}
				const childArr = [];

				await Promise.all([...page.rows].map(async row => {
					const customPage = {
						id: row.content.title,
						title: row.content.title,
						acf: row.content.content,
						cta_link: row.content.link.url,
						cta_title: row.content.link.title,
						cta_target: row.content.link.target,
						img: null,
						img_details: null,
						imgFocalX: null,
						imgFocalY: null
					};

					if ( ! row.image ) {
						return customPage;
					}

					await fetch(`${WP_API}/media/${row.image}`)
						.then( res => res.json() )
						.then( image => {
							if (image.guid) {
								customPage.img = image.guid.rendered;
								customPage.img_details = image.media_details;
							}

							if (image.meta?._focal_point_kiosk_x) {
								customPage.imgFocalX = image.meta._focal_point_kiosk_x;
								customPage.imgFocalY = image.meta._focal_point_kiosk_y;
							}
						});

					childArr.push(customPage);
					return false;
				}));

				section.id = page.image;
				section.title = page.title;
				section.children = childArr;
				rows.push(section);

				isLoading(false);

				return false;
			}));

			setSections(rows);
		}
		awaitImages();

	}, []);

	if (!data) {
		history.push('/')
		return false;
	}

	window.scrollTo({ top: 0 });

	function showDisclosure() {
		let { disclosure_content } = data.acf;

		// send up disclosure content
		// to parent component
		if (disclosure_content) {
			hasDisclosure(true, disclosure_content);
		}

		setTimeout(() => {
			const supTags = content.current.querySelectorAll('sup');
			// console.log(`supTags`, document.getElementById('disclosure').getBoundingClientRect().top);
			[...supTags].map((el) => {
				el.addEventListener('click', () => {
					window.scrollTo({
						top: document.getElementById('disclosure').getBoundingClientRect().top,
						behavior: 'smooth'
					})
				})
			})
		}, 500);
	}

	return (
		<motion.section
			className="v-dc"
			ref={content}
			animate={{ opacity: [0, 1] }}
			transition={{ duration: .2 }}
		>
			<div className="container">
				{
					(sections) &&
					sections.map(section => {
						return (
							<div key={section.id} className="v-dc__section">
								<div className="v-dc__section-title">
									<h1 dangerouslySetInnerHTML={{ __html: section.title}} />
								</div>
								{
									section.children.map((card) => {
										return (
											<div key={card.id} className="v-dc__card-wrap">
												<div className="v-dc__card-img-wrap">
													<img className="v-dc__card-img" style={{'objectPosition': `${card.imgFocalX}%  ${card.imgFocalY}%`}} src={card.img} alt="placeholder" width="800" height="600" />
												</div>
												<div className="v-dc__card-content">
													<h2 className="v-dc__card-title">
														{card.title}
													</h2>
													<div className="v-dc__card-text" dangerouslySetInnerHTML={{ __html: card.acf}} />
													<div className="v-dc__card-button">
														{
															( card.cta_link.length > 0 && card.cta_link.indexOf(`wesbanco.${ENV}/locations`) < 0 ) &&
																<a href={card.cta_link} target={card.cta_target} className="v-button">{card.cta_title}</a>
														}
													</div>
												</div>
											</div>
										)
									})
								}
							</div>
						)
					})
				}
			</div>
		</motion.section>
	)
}

export default DetailComparison
