import React from 'react'

const Loading = () => {
	return (
		<div className="v-loading">
			<p className="gamma">Loading...</p>
			<div className="v-hp-hero__bullets glide__bullets">
				<div className="v-hp-hero__bullet" style={{opacity: 0.5}} />
				<div className="v-hp-hero__bullet" style={{opacity: 0.5}} />
				<div className="v-hp-hero__bullet" style={{opacity: 0.5}} />
			</div>
		</div>
	)
}

export default Loading;
