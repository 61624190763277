import React from 'react'
import { useHistory } from 'react-router-dom'
import ReactGA from "react-ga4";

const Four04 = ({ isLoading }) => {
	const history = useHistory();

	isLoading(false);

	try {
		setTimeout(_ => {
			if (window.location.hostname === 'kiosk.wesbanco.com') {
				ReactGA.initialize("G-HVTY8XYXVM");
				ReactGA.send({ hitType: "pageview", page_title: "404" });
			}

		}, 2000);
	} catch (err) {
		console.log(`ERROR with G4:`, err)
	}

	return (
		<>
			<div className="container v-404">
				<h1>404: Page not found</h1>
				<button
					href="/list"
					className="v-button"
					onClick={() => { history.push('/') }}
				>Return home?</button>
			</div>
		</>
	)
}

export default Four04
