import {React, useEffect, useState} from 'react'
import ReactGA from "react-ga4";

// utils
import { WP_KIOSK_MENU_API } from "../../utils/constants";

// components
import CardList from '../../components/card-list'

// styles
import '../../scss/global.scss';

export const FrontPage = ({ isLoading, title, hasDisclosure }) => {
  const [pages, setPages] = useState(null);

	useEffect(() => {
		isLoading(true);
		hasDisclosure(false);

		// G4 Tracking
		try {
			setTimeout(_ => {
				if (window.location.hostname === 'kiosk.wesbanco.com') {
					ReactGA.initialize("G-HVTY8XYXVM");
					ReactGA.send({ hitType: "pageview", page_title: `Homepage` });
				}
			}, 2000);
		} catch (err) {
			console.log(`ERROR with G4:`, err)
		}

		fetch(`${WP_KIOSK_MENU_API}`)
		.then(response => response.json())
		.then(data => {
			isLoading(false);
			setPages(data);
		})
	}, []);

	// render
	return (
		<div>
			{
				(pages) &&
				<CardList pages={pages} />
			}
		</div>
	)
}


export default FrontPage;
