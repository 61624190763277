import {React, useEffect, useState} from 'react'
import { useLocation } from "react-router-dom";

import { WP_API, WP_MENU_API, WP_ACF_OPTIONS_ENDPOINT, WP_KIOSK_INVESTMENT_MENU_API } from "../../utils/constants";

// components
import CardList from '../../components/card-list'

// styles
import '../../scss/global.scss';

export const SecondaryPage = ({ isLoading, title, hasDisclosure }) => {
	const [oneOffImages, setOneOffImages] = useState(null)
  const [secondaryPages, setSecondaryPages] = useState(null)
  const [pages, setPages] = useState(null)
	const [pageData, setPageData] = useState(null);
	const location = useLocation();

	useEffect(() => {
		isLoading(true);
		hasDisclosure(false);

		async function waiting() {

			await fetch(WP_MENU_API)
			.then(response => response.json())
			.then( async data => {

				let children = [];
				let selectedPages = [];
				let isPersonal = false;
				let isBusiness = false;
				let isInvestments = false;
				let imgId;

				[...data.items].forEach(el => {
					if (el.title.toLowerCase() === location.state.title.toLowerCase()) {
						children.push(el.children);
						if (el.title.toLowerCase() === 'personal') {
							isPersonal = true;
						} else if (el.title.toLowerCase() === 'business') {
							isBusiness = true;
						} else if (el.title.toLowerCase() === 'investments') {
							isInvestments = true;

							fetch(`${WP_KIOSK_INVESTMENT_MENU_API}`)
							.then(response => response.json())
							.then(data => {
								isLoading(false);

								const cleanedData = async () => {

									return Promise.all(data.map(el => {
										if (el.title === "Trust &#038; Investment<sup>1</sup>") {
											el.title = 'Investments';
											el.onSecondary = true;
											return el;
										} else if (el.title === "WesBanco Insurance Services<sup>1</sup>") {
											el.title = 'Life Insurance';
											return el;
										} else if (el.title === "Trust Services<sup>1</sup>") {

											return fetch(`${WP_API}/${(el.object === 'page') ? el.object+'s' : el.object}/${el.id}`)
												.then(response => response.json())
												.then( data => {

													el = {
														id: data.id,
														title: 'Trusts',
														parent: data.parent,
														acf: data.acf,
													};

													if (data.acf.featured_image) {
														imgId = data.acf.featured_image;
													} else if (data.acf.page_thumbnail) {
														imgId = data.acf.page_thumbnail;
													} else if (data.acf.background_image) {
														imgId = data.acf.background_image;
													}

													return fetch(`${WP_API}/media/${imgId}`)
														.then( images => images.json() )
														.then( img => {

															return el = {
																id: data.id,
																title: 'Trusts',
																parent: data.parent,
																acf: data.acf,
																img: img.guid.rendered,
																img_details: img.media_details,
																imgFocalX: img.meta._focal_point_kiosk_x,
																imgFocalY: img.meta._focal_point_kiosk_y
															};
													});
											});

										} else if (el.title === "Retirement Planning<sup>1</sup>") {
											el.title = 'Retirement';
											return el;
										} else if (el.title === "Investment Management Services<sup>1</sup>") {
											return fetch(`${WP_API}/${(el.object === 'page') ? el.object+'s' : el.object}/${el.id}`)
												.then(response => response.json())
												.then( data => {

													el = {
														id: data.id,
														title: 'Investment Advice',
														parent: data.parent,
														acf: data.acf,
													};

													if (data.acf.featured_image) {
														imgId = data.acf.featured_image;
													} else if (data.acf.page_thumbnail) {
														imgId = data.acf.page_thumbnail;
													} else if (data.acf.background_image) {
														imgId = data.acf.background_image;
													}

													return fetch(`${WP_API}/media/${imgId}`)
														.then( images => images.json() )
														.then( img => {

															return el = {
																id: data.id,
																title: 'Investment Advice',
																parent: data.parent,
																acf: data.acf,
																img: img.guid.rendered,
																img_details: img.media_details,
																imgFocalX: img.meta._focal_point_kiosk_x,
																imgFocalY: img.meta._focal_point_kiosk_y
															};
													});
											});
										} else {
											return el;
										}

									}));
								}

								cleanedData().then(res => {
									setPages(res);
								});

							});
						}
					}
				});

				await Promise.all([...children[0]].map(async child => {
					let imgData;

					// switching between personal
					// and business slugs
					// ! have to be selective here bc
					// ! not all menu items are shown
					switch(child.object_slug) {
						case "personal-checking":
							imgData = await getCardImage(child.object, child.object_id, child.id);
							selectedPages[0] = ({
								title: child.title,
								id: child.id,
								slug: child.object_slug,
								children: child.children,
								object_id: child.object_id,
								object: child.object,
								img: imgData.img,
								img_details: imgData.img_details,
								imgFocalX: imgData.imgFocalX,
								imgFocalY: imgData.imgFocalY
							});
							break;
						case "personal-savings":
							imgData = await getCardImage(child.object, child.object_id, child.id);
							selectedPages[1] = ({
								title: child.title,
								id: child.id,
								slug: child.object_slug,
								children: child.children,
								object_id: child.object_id,
								object: child.object,
								img: imgData.img,
								img_details: imgData.img_details,
								imgFocalX: imgData.imgFocalX,
								imgFocalY: imgData.imgFocalY
							});
							break;
						case "loans-credit-cards":
							imgData = await getCardImage(child.object, child.object_id, child.id);
							selectedPages[2] = ({
								title: child.title,
								id: child.id,
								slug: child.object_slug,
								children: child.children,
								object_id: child.object_id,
								object: child.object,
								img: imgData.img,
								img_details: imgData.img_details,
								imgFocalX: imgData.imgFocalX,
								imgFocalY: imgData.imgFocalY
							});
							break;
						case "online-and-mobile-banking":
							imgData = await getCardImage(child.object, child.object_id, child.id);
							selectedPages[3] = ({
								title: child.title,
								id: child.id,
								slug: child.object_slug,
								children: child.children,
								object_id: child.object_id,
								object: 'pages',
								img: imgData.img,
								img_details: imgData.img_details,
								imgFocalX: imgData.imgFocalX,
								imgFocalY: imgData.imgFocalY
							});
							break;
						case "business-checking":
							imgData = await getCardImage(child.object, child.object_id, child.id);
							selectedPages[0] = ({
								title: child.title,
								id: child.id,
								slug: child.object_slug,
								children: child.children,
								object_id: child.object_id,
								object: 'business',
								img: imgData.img,
								img_details: imgData.img_details,
								imgFocalX: imgData.imgFocalX,
								imgFocalY: imgData.imgFocalY
							});
							break;
						case "business-savings":
							imgData = await getCardImage(child.object, child.object_id, child.id);
							selectedPages[1] = ({
								title: child.title,
								id: child.id,
								slug: child.object_slug,
								children: child.children,
								object_id: child.object_id,
								object: 'business',
								img: imgData.img,
								img_details: imgData.img_details,
								imgFocalX: imgData.imgFocalX,
								imgFocalY: imgData.imgFocalY
								});
								break;
						case "business-loans-and-credit-cards":
							imgData = await getCardImage(child.object, child.object_id, child.id);
							selectedPages[2] = ({
								title: child.title,
								id: child.id,
								slug: child.object_slug,
								children: child.children,
								object_id: child.object_id,
								object: 'business',
								img: imgData.img,
								img_details: imgData.img_details,
								imgFocalX: imgData.imgFocalX,
								imgFocalY: imgData.imgFocalY
							});
							break;
						case "business-services":
							imgData = await getCardImage(child.object, child.object_id, child.id);
							selectedPages[3] = ({
								title: child.title,
								id: child.id,
								slug: child.object_slug,
								children: child.children,
								object_id: child.object_id,
								object: 'business',
								img: imgData.img,
								img_details: imgData.img_details,
								imgFocalX: imgData.imgFocalX,
								imgFocalY: imgData.imgFocalY
							});
							break;
						case "business-online-and-mobile-banking":
							imgData = await getCardImage(child.object, child.object_id, child.id);
							selectedPages[4] = ({
								title: child.title,
								id: child.id,
								slug: child.object_slug,
								children: child.children,
								object_id: child.object_id,
								object: 'business',
								img: imgData.img,
								img_details: imgData.img_details,
								imgFocalX: imgData.imgFocalX,
								imgFocalY: imgData.imgFocalY
							});
							break;
						default:
							return;
					}
				}));

				async function getCardImage (object, object_id, id) {
					const imgId = await fetch(`${WP_API}/${(object === 'page') ? object+'s' : object}/${object_id}`)
					.then(response => response.json())
					.then( async data => {

						let id;
						if (data.acf.featured_image) {
							id = data.acf.featured_image;
						} else if (data.acf.page_thumbnail) {
							id = data.acf.page_thumbnail;
						} else if (data.acf.background_image) {
							id = data.acf.background_image;
						}

						return id;
					})

					let imgInfo = await fetch(`${WP_API}/media/${imgId}`)
					.then( images => images.json() )
					.then( img => {

						return {
							img: img.guid.rendered,
							img_details: img.media_details,
							imgFocalX: img.meta._focal_point_kiosk_x,
							imgFocalY: img.meta._focal_point_kiosk_y
						}
					});

					return imgInfo;
				}

				// get oneOff images from acf 'global options'
				fetch(`${WP_ACF_OPTIONS_ENDPOINT}`)
					.then(response => response.json())
					.then(data => {
					// set slider content

						if (data) {

							// set custom 'personal' pages
							if (isPersonal) {
								selectedPages.push({
									title: 'Financial Wellness',
									id: '_1',
									object: 'f-n',
									children: 'none',
									img: data.acf.kiosk_image.kiosk_image_wellness.sizes.medium
								});
								selectedPages.push({
									title: 'Calculators & Tools',
									id: '_2',
									object: 'f-n',
									children: 'none',
									img: data.acf.kiosk_image.kiosk_image_calculators.sizes.medium
								});

								if (selectedPages) {
									setSecondaryPages(selectedPages);
									isLoading(false);
								}
							}

							// set custom 'business' pages
							if (isBusiness) {

								selectedPages.push({
									title: 'Growing Your Business',
									id: '_2',
									object: 'f-n',
									children: 'none',
									img: data.acf.kiosk_image.kiosk_image_growing_business.sizes.medium
								});

								if (selectedPages) {
									setSecondaryPages(selectedPages);
									isLoading(false);
								}
							}
						}
				});
			});
		}
		waiting();

	}, []);

	// render
	return (
		<div>
			{
				(pages || secondaryPages) &&
				<CardList pages={(pages) ? pages : secondaryPages} oneOffImages={oneOffImages} />
			}
		</div>
	)
}


export default SecondaryPage;
