import React from 'react'

const Disclosure = ({ content }) => {

	return (
		(content) &&
		<section className="v-disclosure" id="disclosure">
			<div className="container">
				<div className="v-disclosure__content" dangerouslySetInnerHTML={{ __html: content }} />
			</div>
		</section>
	)
}

export default Disclosure;
