import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import React, { useEffect, useState } from 'react'

// utils
import { WP_ACF_OPTIONS_ENDPOINT } from "../utils/constants";

// components
import Header from '../components/header'
import Main from '../components/main'
import Disclosure from '../components/disclosure'
import Footer from '../components/footer'
import Loading from '../components/loading'
import Button from '../components/button/'
import Slider from '../components/slider'
import { LogoHeader } from '../components/logo/'

// Templates
import FrontPage from './frontpage'
import ListIntro from './list-intro'
import DetailTwoColumn from './detail-two-column'
import DetailCareers from './detail-careers'
import DetailComparison from './detail-comparison'
import DetailInsight from './detail-insight'
import DetailNoHero from './detail-no-hero'
import ListInsight from './list-insight'
import Four04 from './404'
import SecondaryPage from "./secondary-page"

// styles
import '../scss/global.scss'

function App() {
	const [slides, setSlides] = useState(null)
	const [loading, setLoading] = useState(true);
	const [disclosure, setDisclosure] = useState(false);
	const [disclosureContent, setDisclosureContent] = useState(null);

	function updateLoading(loading) {
		setLoading(loading);
	}

	function showDisclosure(disclose, content) {
		setDisclosure(disclose);
		setDisclosureContent(content)
	}

	useEffect(() => {
		// get slides
		async function waiting() {
			await fetch(`${WP_ACF_OPTIONS_ENDPOINT}`)
			.then(response => response.json())
			.then(data => {
				// set slides
				setSlides(data);
			});
		}
		waiting();
	}, [])

  return (
		<div className="app">
			<Router>
				<Header>
					<Button type='back' title='Back' />
					<LogoHeader />
				</Header>
					<Main>
						{
							// show slider if homepage && secondary page
							((window.location.pathname === '/' || window.location.pathname.indexOf('secondary') > -1) && slides ) &&
								<Slider slides={slides} />
						}
						<Switch>
							{/* App routes */}
							<Route path='/' exact render={() => <FrontPage title='Frontpage' hasDisclosure={showDisclosure} isLoading={updateLoading} hasSlides={slides} />} />
							<Route path='/secondary/:object/:id' exact render={() => <SecondaryPage title='Secondary Page' hasDisclosure={showDisclosure} isLoading={updateLoading} hasSlides={slides} />} />
							<Route path='/list-intro/:object/:id' render={() => <ListIntro data={null} hasDisclosure={showDisclosure} isLoading={updateLoading} />} />
							<Route path='/list-insight/' render={() => <ListInsight hasDisclosure={showDisclosure} isLoading={updateLoading} />} />
							<Route path='/detail-comparison/' render={() => <DetailComparison hasDisclosure={showDisclosure} isLoading={updateLoading} />} />
							<Route path='/detail-careers/' render={() => <DetailCareers hasDisclosure={showDisclosure} isLoading={updateLoading} />} />
							<Route path='/detail-insight/' render={() => <DetailInsight hasDisclosure={showDisclosure} isLoading={updateLoading} />} />
							<Route path='/detail-no-hero/' render={() => <DetailNoHero hasDisclosure={showDisclosure} isLoading={updateLoading} />} />
							<Route path='/detail-two-column/' render={() => <DetailTwoColumn hasDisclosure={showDisclosure} isLoading={updateLoading} />} />
							<Route render={() => <Four04 isLoading={updateLoading} />} />
							{/* End app routes */}

						</Switch>
						{
							(loading) &&
							<Loading />
						}
					</Main>
					{
						disclosure && <Disclosure content={disclosureContent} />
					}
				<Footer />
			</Router>
    </div>
  );
}

export default App;
