import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from "react-router-dom"
import ReactGA from "react-ga4";

import { WP_API, WP_MENU_API } from "../../utils/constants";

// components
import Intro from '../../components/intro'
import CardList from '../../components/card-list'

export const ListIntro = ({ isLoading, hasDisclosure }) => {
	const [ pageData, setPageData ] = useState(null);
	const [cards, setCards] = useState(null);
	const [error, setError] = useState(null)
	const { object, id } = useParams();
	const location = useLocation();
	const locationURL = location.pathname.split('/');
	const title = locationURL[2].split("-").map(word => `${word[0].toUpperCase()}${word.substr(1)}`).join(" ");

	let isPersonal = title.toLowerCase() === 'personal' ? true : false;
	let isBusiness = title.toLowerCase() === 'business' ? true : false;

	// get page by id
	// `object` determines api route
	useEffect(() => {
		let imgId;
		let apiRoute = null;

		isLoading(true);
		hasDisclosure(false);

		// G4 Tracking
		try {
			setTimeout(_ => {
				if (window.location.hostname === 'kiosk.wesbanco.com') {
					ReactGA.initialize("G-HVTY8XYXVM");
					ReactGA.send({ hitType: "pageview", page_title: `${title}` });
				}
			}, 2000);
		} catch (err) {
			console.log(`ERROR with G4:`, err)
		}

		// depending on where the user is coming from
		// the api route endpoint needs to change
		if (isPersonal || isBusiness) {
			apiRoute = `${WP_API}/${(object === 'page') ? object+'s' : object}/${id}`
		} else {
			apiRoute = `${WP_API}/${(object === 'page') ? object+'s' : object}/${id}`;
		}

		// if object === page add an 's' to route
		fetch(`${apiRoute}`)
		.then(response => response.json())
		.then( async data => {

			if (data.acf.featured_image) {
				imgId = data.acf.featured_image;
			} else if (data.acf.page_thumbnail) {
				imgId = data.acf.page_thumbnail;
			} else if (data.acf.background_image) {
				imgId = data.acf.background_image;
			}

			fetch(`${WP_API}/media/${imgId}`)
			.then( images => images.json() )
			.then( img => {

				setPageData({
					id: data.id,
					title: data.title.rendered,
					parent: data.parent,
					acf: data.acf,
					img: img.guid.rendered,
					img_details: img.media_details,
					imgFocalX: img.meta._focal_point_kiosk_x,
					imgFocalY: img.meta._focal_point_kiosk_y
				});
			});

			// get child pages, store content to
			// avoid api call on next page
			fetch(`${WP_API}/${(object === 'page') ? object+'s' : object}?parent=${data.id}`)
				.then(response => response.json())
				.then( async data => {
					isLoading(false);


					const cleanedData = await Promise.all(data.map( async (page) => {

						const customPage = {
							id: page.id,
							parent: page.parent,
							title: page.title.rendered,
							slug: page.slug,
							template: page.template,
							acf: page.acf,
							img: null,
							img_details: null,
							imgFocalX: null,
							imgFocalY: null
						};

						if (page.acf.featured_image) {
							imgId = page.acf.featured_image;
						} else if (page.acf.page_thumbnail) {
							imgId = page.acf.page_thumbnail;
						} else if (page.acf.background_image) {
							imgId = page.acf.background_image;
						}

						if ( !imgId ) {
							return customPage;
						}

						await fetch(`${WP_API}/media/${imgId}`)
							.then( images => images.json() )
							.then( image => {
								if (image.guid) {
									customPage.img = image.guid.rendered;
									customPage.img_details = image.media_details;
								}

								if (image.meta?._focal_point_kiosk_x) {
									customPage.imgFocalX = image.meta._focal_point_kiosk_x;
									customPage.imgFocalY = image.meta._focal_point_kiosk_y;
								}
							});

						return customPage;
					})
				);

				setCards(cleanedData);
			})
			.catch(error => {
				setError(error);
				console.log('Error:', error);
			});

			return data
		});


	}, []);


	// depending on `object` type may need
	// to add/remove components below
	return (
		<div>
			{
				<>
					{
						(pageData) &&
						<Intro
							title={pageData.title}
							intro={pageData.acf.intro_content}
							img={pageData.img}
							imgData={pageData}
						/>
					}

					{
						(pageData && cards) &&
						<CardList
							parent={pageData.id}
							pages={cards}
						/>
					}
				</>
			}

		</div>
	)
}

export default ListIntro;
